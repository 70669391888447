import "./PrimaryButton.scss";

function PrimaryButton({link, onClick, label}) {
    return ( 
        <button
            href={link}
            onClick={onClick}
        >
            {label}
        </button>
     );
}

export default PrimaryButton;