import "./SecondaryButton.scss";

function SecondaryButton({link, onClick, spanText, label}) {
    return ( 
        <a
            href={link}
            onClick={onClick}
        >
            {label}
            <span className="secText">{spanText}</span>
        </a>
     );
}

export default SecondaryButton;