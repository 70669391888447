import "./Main.scss";
import { ReactComponent as Logo } from "./Logo.svg";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import Spline from '@splinetool/react-spline';

function Main() {
    return ( 
        <div className="main">
            <div className="left--side">
                <div className="upper">
                    <div className="logo">
                        <Logo />
                    </div>
                    <h2>«Домашняя» дизайн-студия с опытом &nbsp;проектирования цифровых продуктов и&nbsp;cложных систем.</h2>
                    <h2>Большая экспертиза в решении сложных и&nbsp;неординарных задач в продуктовом дизайне, так как наш опыт связан с&nbsp;проектированием продуктов в области информационной безопасности, программного обеспечения.</h2>
                    <h2>Ознакомиться с нашими проектами, рабочими процессами и форматом работы можете, скачав нашу презентацию.</h2>
                    <div className="secondary--button">
                        <SecondaryButton label="Скачать презентацию" spanText="PDF" link="../ampermate.pdf"/>
                    </div>
                </div>
                <div className="bottom">
                    <h2>Если вы заинтересовались, можете оставить заявку, описав свою задачу.</h2>
                    <a href="/form"><PrimaryButton label="Оставить заявку"/></a>
                    <div className="caption--text">
                        <p>Для удобства можете написать нам в <a href="https://t.me/ampermate" target="_blank"><span className="telegram-text">телеграм</span></a> или в <a href="https://api.whatsapp.com/send/?phone=79932646726&text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82%21+%EF%BF%BD+%D0%9C%D0%B5%D0%BD%D1%8F+%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82...&type=phone_number&app_absent=0" target="_blank"><span className="whatsapp-text">ватсап</span></a></p>
                    </div>
                </div>
            </div>

            <div className="right--side">
                <Spline scene="https://prod.spline.design/ZECGTzWt7LTx1u92/scene.splinecode" />
            </div>
        </div>
     );
}

export default Main;