import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import "./Form.scss";
import emailjs from '@emailjs/browser';
import { ReactComponent as Logo } from "../Main/Logo.svg";

function Form() {
    const form = useRef();
    const [statusMessage, setStatusMessage] = useState("");
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    

    const sendEmail = (e) => {
      e.preventDefault();
      setName('');
      setContact('');
      setMessage('');
  
      emailjs.sendForm('service_xu6ob72', 'template_csb6qup', form.current, 'SnMGpJWvx_zEMMA8J')
        .then((result) => {
            setStatusMessage("Заявка успешно отправлена! Скоро мы с вами свяжимся");
        }, (error) => {
            console.log(error.text);
        });
    };
    return ( 
        <div className="form">
            <div className="logo">
            <Link to="/"><Logo/></Link>
            </div>
            <h3>Давайте ближе познакомимся</h3>
            <p>Расскажите коротко о своих задачах, а мы придумаем решение.</p>
            <div className="inputs">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="Имя" id="name" onChange={event => setName(event.target.value)} value={name}/>
                    <input type="text" name="contact" placeholder="Почта, телефон или телеграм" id="contact" onChange={event => setContact(event.target.value)} value={contact}/>
                    <textarea name="message" placeholder="Опишите задачу" id="" cols="30" rows="10" onChange={event => setMessage(event.target.value)} value={message}></textarea>
                    <button type="submit">Отправить</button>
                    <h4>{statusMessage}</h4>
                </form>
            </div>
        </div> 
    );
}

export default Form;