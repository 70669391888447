import "./assets/styles/styles.scss";
import Main from "./pages/Main/Main";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Main/>
      </header>
    </div>
  );
}

export default App;
